.NoFile {
  text-align: center;
}
.FileContainer {
  display: inline-block;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  background-color: white;
  border-radius: 0.5rem;
  box-shadow: hsla(0, 0%, 50%, 0.4) 0px 4px 4px;
  overflow: hidden;
  margin-bottom: 1rem;
}
.FileContainerBar {
  height: 6px;
}
.FileContainer:hover .FileContainerBar {
  background-color: #ee7155;
}
.Content {
  padding-left: 1rem;
  padding-right: 1rem;
  padding-bottom: 0.8rem;
  padding-top: calc(0.8rem - 6px);
}
.Container {
  margin: 0rem 1.5rem;
}
.EnvPicker {
  text-align: center;
  margin-bottom: 1.5rem;
  background-color: white;
  padding-top: 0.3rem;
  box-shadow: hsla(0, 0%, 50%, 0.4) 0px 4px 4px;
}
.EnvPicker button {
  padding: 0.5rem;
  font-size: 1.2rem;
}
.EnvActive {
  border-bottom: 3px solid #ee7155;
}
.Version {
  margin-left: 0.5rem;
  margin-top: 1rem;
  margin-bottom: 0.5rem;
}

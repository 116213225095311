.Title {
  padding: 1rem 0px;
  background-color: #7dc9e7;
  color: white;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.Title img {
  height: 2rem;
  margin-right: 1rem;
}
.Loading {
  text-align: center;
  margin-top: 4rem;
}
.Login {
  margin: 2rem;
  display: flex;
  flex-direction: column;
}
.LoginContainer {
  min-height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.Input {
  font-size: 1.4rem;
  padding: 0.4rem;
}
.Submit {
  font-size: 1.4rem;
  margin: 1rem;
}
